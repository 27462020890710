
// Libraries
import * as React from 'react'
import { graphql } from 'gatsby'
import { useState } from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import EventSearch from '../../components/eventSearch'
import EventList from '../../components/eventList'

export default function EventsPage({ location }) {
	// Initialise search query state
	const [searchQuery, setSearchQuery] = useState()

	// See if search query has already been set via navigate
	if(location?.state?.searchQuery) {
		setSearchQuery(location.state.searchQuery)
	}

	// Search component callback
	const handleSearch = event => {
		// Set search query state
		setSearchQuery(event.target.value)
	}

	return (
		<Layout>
			<Seo title="Event Search" />
			<EventSearch onSearch={handleSearch} />
			<EventList searchQuery={searchQuery} />
		</Layout>
	)
}

// Query GraphQL for featured events
export const query = graphql`
	{
		event(featured: {eq: 1}) {
			id
			name
			featured
			artist {
				name
			}
			slug
			starts
			ends
			image
			venue {
				name
			}
		}
	}
`
